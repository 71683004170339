<template>
  <Stack align="stretch" direction="col" gap="none" class="ui-w-full">
    <Stack align="center" justify="between">
      <label
        :for="id"
        :class="[
          'ui-text-base ui-font-bold',
          required && requiredClasses,
          !labelVisible && 'ui-sr-only',
          disabled && 'ui-text-grey-default',
        ]"
      >
        {{ label }}
      </label>
      <Stack v-if="linkHref && linkText">
        <Link
          :tag="linkTag"
          :href="disabled ? null : linkHref"
          :class="[linkClass, disabled && '!ui-text-grey-default']"
          @click="emit('link:click')"
        >
          {{ linkText }}
        </Link>
      </Stack>
    </Stack>
    <Stack class="ui-relative">
      <!-- input -->
      <input
        :id="id"
        :type="inputType"
        :inputmode="inputMode"
        :enterkeyhint="inputType === 'search' ? 'search' : 'enter'"
        :class="[
          'ui-relative ui-w-full ui-my-[6px] ui-px-[10px] ui-py-[9px] ui-border-sm ui-rounded-sm ui-font-normal ui-text-base ui-leading-base ui-text-charcoal-default outline-none placeholder:ui-text-charcoal-lighter',
          'disabled:ui-ring-0 disabled:ui-bg-grey-lighter disabled:ui-border-grey-lighter disabled:ui-text-grey-default',
          inputStyles,
        ]"
        :placeholder="placeholder"
        :data-test-id="dataTestId"
        :error="error"
        :warning="warning"
        :success="success"
        :disabled="disabled"
        v-model="inputValue"
        v-bind="$attrs"
        autocomplete="one-time-code"
        @keydown="handleKeyDown"
        @paste="handlePaste"
      />
      <Stack
        v-if="isPassword"
        :class="[
          'ui-absolute ui-right-[10px] ui-top-1/2 -ui-translate-y-1/2 ui-cursor-pointer ui-text-mkm-blue-light',
          'hover:ui-text-mkm-blue-default',
          disabled && '!ui-text-grey-default',
        ]"
        @mouseover="iconHovered = true"
        @mouseleave="iconHovered = false"
        @mousedown="iconActive = true"
        @mouseup="iconActive = false"
        @click="togglePasswordVisibility"
        :data-test-id="generateId('toggleVisibilityBtn')"
      >
        <Icon :name="isPasswordVisible ? 'hide' : 'show'" :size="16" />
      </Stack>
      <!-- Search input -->
      <Stack
        v-if="isSearch"
        tag="div"
        gap="xs"
        :class="[
          'ui-absolute ui-right-[10px] ui-top-1/2 -ui-translate-y-1/2 ui-cursor-pointer ui-text-mkm-blue-light',
          'hover:ui-text-mkm-blue-default',
        ]"
      >
        <button
          class="ui-text-charcoal-default"
          v-if="inputValue"
          @click="inputValue = ''"
          :data-test-id="generateId('clearBtn')"
          aria-label="clear search"
        >
          <Icon name="times-thin" :size="16" />
        </button>
        <button
          type="button"
          @click="emit('search:inputValue', inputValue)"
          aria-label="search"
        >
          <Icon
            name="search"
            :size="16"
            class="ui-hidden md:ui-block"
            data-test-id="searchBtn"
          />
        </button>
      </Stack>
    </Stack>
    <Stack
      v-if="isSearch && showSuggestions"
      class="ui-bg-white ui-border-sm rounded-sm ui-py-2xs ui-border-grey-default"
    >
      <ul class="ui-max-h-[300px] ui-overflow-scroll ui-w-full">
        <li
          v-for="(suggestion, index) in suggestions"
          :key="index"
          @click="emit('click:suggestion', suggestion.meta)"
          class="ui-cursor-pointer ui-py-2xs hover:ui-bg-grey-lighter ui-px-[10px]"
        >
          {{ suggestion.display }}
        </li>
      </ul>
    </Stack>
    <Text
      :data-test-id="generateId('message')"
      v-if="(error || warning || success) && messageText"
      size="caption"
      weight="semi"
      :class="[
        'ui-text-charcoal-lighter',
        error && 'ui-text-red-dark',
        warning && 'ui-text-orange-dark',
        success && 'ui-text-green-dark',
        disabled && '!ui-text-charcoal-lighter',
      ]"
    >
      {{ messageText }}
    </Text>
    <Text
      v-if="helpText"
      size="caption"
      weight="semi"
      class="ui-text-charcoal-lighter"
    >
      {{ helpText }}
    </Text>
  </Stack>
</template>

<script lang="ts" setup>
import Icon from "../../Icon/Icon.vue";
import Link from "../../Link/Link.vue";
import Stack from "../../Layout/Stack/Stack.vue";
import Text from "../../Typography/Text/Text.vue";
import type { InputProps } from "./types";
import { computed, Ref, ref, watch } from "vue";
import { disableNonNumericKeys, isNumeric } from "mkm-avengers";

const props = withDefaults(defineProps<InputProps>(), {
  size: "md",
  labelVisible: true,
  placeholder: "",
  required: false,
  error: false,
  warning: false,
  success: false,
  disabled: false,
  isPassword: false,
  linkTag: "a",
  linkClass:
    "ui-text-sm ui-font-semi ui-leading-sm ui-text-mkm-blue-light hover:ui-text-mkm-blue-default focus:ui-text-mkm-blue-lighter active:ui-text-mkm-blue-lighter",
  type: "text",
  inputMode: "text",
  numbersOnly: false,
});

const emit = defineEmits<{
  (event: "update:inputValue", value: string | number): void;
  (event: "click:suggestion", value: any): void;
  (event: "search:inputValue", value: string | number): void;
  (event: "link:click"): void;
}>();

const model = ref<string | number>(props.modelValue);
const isPasswordVisible = ref<boolean>(false);
const iconHovered = ref<boolean>(false);
const iconActive = ref<boolean>(false);
const showSuggestions: Ref<boolean> = ref<boolean>(false);

const generateId = (suffix: string) =>
  props.dataTestId ? `${props.dataTestId}-${suffix}` : suffix;

const inputValue = computed({
  get: () => model.value,
  set: (value) => {
    emit("update:inputValue", value);
    model.value = value;
  },
});

const inputType = computed(() =>
  props.isPassword && !isPasswordVisible.value
    ? "password"
    : props.isSearch
    ? "search"
    : props.type,
);

const inputMode = computed(() =>
  props.isSearch || props.type === "search" || props.inputMode === "search"
    ? "search"
    : props.inputMode,
);

const togglePasswordVisibility = () => {
  if (props.disabled) return;

  isPasswordVisible.value = !isPasswordVisible.value;
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (props.numbersOnly) disableNonNumericKeys(event);
};

const handlePaste = (event: ClipboardEvent) => {
  if (props.numbersOnly) {
    const clipboardData = event.clipboardData?.getData("text/plain");

    if (clipboardData && !isNumeric(clipboardData)) event.preventDefault();
  }
};

defineExpose({
  focus: () => {
    const inputElement = document.querySelector(props.id) as HTMLInputElement;
    if (inputElement) {
      inputElement.focus();
    }
  },
});

const sizeClasses = {
  ["sm"]: "ui-h-md",
  ["md"]: "ui-h-lg",
  ["lg"]: "ui-h-xl",
};

const inputStyles = computed(() => {
  return [
    props.searchClasses ||
      "hover:ui-border-mkm-blue-light hover:ui-ring-[6px] hover:ui-ring-blue-lighter focus:ui-border-mkm-blue-dark focus:ui-ring-[6px] focus:ui-ring-blue-lighter",
    iconHovered.value &&
      !props.disabled &&
      "ui-border-mkm-blue-light ui-ring-[6px] ui-ring-blue-lighter",
    iconActive.value &&
      !props.disabled &&
      "ui-border-mkm-blue-dark ui-ring-[6px] ui-ring-blue-lighter",
    sizeClasses[props.size],
    props.error && "ui-border-red-dark",
    props.warning && "ui-border-orange-dark",
    props.success && "ui-border-green-dark",
    !props.error &&
      !props.warning &&
      !props.success &&
      !props.disabled &&
      "ui-border-grey-default",
  ].filter(Boolean);
});

const requiredClasses =
  "after:!content-['*'] after:ui-ml-3xs after:!ui-text-red-default";

watch(
  () => props.modelValue,
  (updatedModelProps) => {
    model.value = updatedModelProps;
    emit("update:inputValue", model.value);
  },
);

watch(
  () => props.suggestions,
  (updatedValue) => {
    if (updatedValue && updatedValue?.length > 0) {
      showSuggestions.value = true;
      return;
    }
    showSuggestions.value = false;
  },
);
</script>
